import PropTypes from 'prop-types';
import { getPageBySlug } from 'util/wagtailPageApi';
import StreamField from 'components/streamfield/StreamField';
import { withAuth } from 'util/withAuth';

const ContentPage = ({ data }) => {
  return <>{data && <StreamField stream={data?.body} />}</>;
};

ContentPage.propTypes = {
  data: PropTypes.object,
};

ContentPage.defaultProps = {
  data: null,
};

async function getProps({ params, axios }) {
  try {
    const data = await getPageBySlug(params.slug.join('/'), axios);
    return {
      props: {
        data,
      },
      revalidate: 1,
    };
  } catch (e) {
    // if no page found, render 404.jsx page: https://nextjs.org/blog/next-10#redirect-and-notfound-support-for-getstaticprops--getserversideprops
    return {
      notFound: true,
    };
  }
}
export async function getStaticPaths() {
  // const allContentPages = await getAllPageSlugs();
  // we need to update the build to pull from a current api before this can pass circle
  const allContentPages = [];
  return {
    paths:
      allContentPages.map(item => item.meta.htmlUrl.split('/pages')[1]) || [],
    fallback: true,
  };
}
export const getStaticProps = withAuth(getProps);
export default ContentPage;
